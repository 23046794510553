import { DatePipe, TitleCasePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DxDashboardControlModule } from 'devexpress-dashboard-angular';
import { environment } from 'environments/environment';
import { JoyrideModule } from 'ngx-joyride';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { WinAuthInterceptor } from './core/services/winauth.interceptor';
import { SharedModule } from './shared/shared.module';
import { SaHeaderRightButtonsModule } from './templates/sa-header-right-buttons/sa-header-right-buttons.module';
import { SaMainLeftMenuModule } from './templates/sa-main-left-menu/sa-main-left-menu.module';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "bgsColor": "#162F4D",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 50,
  "bgsType": "ball-spin-clockwise",
  "blur": 1,
  "fgsColor": "#162F4D",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "ball-spin-clockwise",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40, 40, 40, 0.3)",
  "pbColor": "#162F4D",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": true,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center"
};

const UI = [
  SaMainLeftMenuModule,
  SaHeaderRightButtonsModule
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule
    , BrowserAnimationsModule
    , AppRoutingModule
    , SharedModule
    , CoreModule
    , NgxUiLoaderModule.forRoot(ngxUiLoaderConfig)
    , NgxUiLoaderRouterModule.forRoot({ showForeground: true, loaderId: ngxUiLoaderConfig.masterLoaderId })
    , NgxUiLoaderHttpModule.forRoot({
      showForeground: true, loaderId: ngxUiLoaderConfig.masterLoaderId,
      exclude:
        [
          `${environment.BASE_URL}/api/user/notifications`,
          `${environment.BASE_URL}/api/user/currentuser`,
          `${environment.BASE_URL}/api/healthcheck/healthcheckindicator`,
          `${environment.BASE_URL}/api/user/updateNotificationIsRead`,
          `${environment.BASE_URL}/api/guest/timeZones`,
          `${environment.BASE_URL}/api/user/timezone`
        ]
    })
    , MonacoEditorModule.forRoot()
    , DxDashboardControlModule
    , JoyrideModule.forRoot()
    , ...UI
  ],
  providers: [DatePipe, TitleCasePipe, {
    provide: HTTP_INTERCEPTORS,
    useClass: WinAuthInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
